/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss?v=1.2.0";
import "./assets/demo/demo.css?v=1.2.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import JobDetailPage from "views/index-sections/crud/JobDetail.js";
import DetailN from "views/index-sections/crud/DetailN";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import AboutPage from "views/examples/About.js";
import SitemapPage from "views/examples/Sitemap.js";

import CategoryPage from "views/examples/Category.js";
import CityDetails from "views/examples/CityDetails.js";

import CategoryDetail from "views/examples/CategoryJob.js";
import CategoryDetailPage from "views/examples/CategoryDetails.js";
import ContactPage from "views/examples/Contact.js";
import CityPage from "views/examples/City.js";

import TermsPage from "views/examples/Terms.js";
import DisclaimerPage from "views/examples/Disclaimer.js";
import ElementPage from "views/ElementPage.js";

import ReactGA from 'react-ga';
import { useEffect } from 'react';
import EmailAlert from "views/examples/JobEmail";
import EmailFeedback from "views/examples/EmailFeedback";
// admin
import AdminLogin from "views/Admin/Login.js";
// search
import SearchPost from "views/Search/Search.js";


ReactDOM.render(

  <BrowserRouter>
    <Switch>
      {/* admin */}
    <Route path="/AdminLogin/" render={(props) => <AdminLogin {...props} />} />
{/* search */}
<Route path="/Search/:search" component={SearchPost}  /> 

    <Route path="/Feedback/" render={(props) => <EmailFeedback {...props} />} />
    <Route path="/JobAlert/" render={(props) => <EmailAlert {...props} />} />

      <Route path="/index" render={(props) => <LandingPage {...props} />} />

      <Route path="/sitemap"render={(props) => < SitemapPage {...props} />} />
      <Route path="/Job/:id" component={JobDetailPage}  /> 
      <Route path="/JobDetail/:id" component={DetailN}  />

      <Route path="/City/:city" component={CityPage}  /> 

      <Route path="/elementP" render={(props) => <ElementPage {...props} />} />

      <Route path="/CityDetails/" render={(props) => <CityDetails {...props} />} />
      <Route path="/category/" render={(props) => <CategoryPage {...props} />} />
      <Route path="/CategoryData/:category" component={ CategoryDetailPage}  />

      <Route path="/CategoryDetails/:category" component={CategoryDetail}  />

      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/about-page"render={(props) => <AboutPage {...props} />} />
      <Route  path="/terms"  render={(props) => <TermsPage {...props} />} />
      <Route  path="/Disclaimer"  render={(props) => <DisclaimerPage {...props} />} />
      <Route
        path="/contact-page"
        render={(props) => <ContactPage {...props} />}
      />
      <Route
        path="/register-page"
        render={(props) => <RegisterPage {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
