import React, { Component } from "react";
import {useParams} from 'react-router-dom';
import Axios from 'axios';
import Actions from 'views/index-sections/crud/Actions.js';

class CategoryJob extends Actions {
    constructor(props) {
        super(props);
        this.state = {users:null,msg:"API NOT ...."};
    }
    
    componentDidMount() {
        let category = this.props.match.params.category;
        this.fetchcat(category);
        console.log("%c___", "background:blue;padding:20px;");
        
        console.log(this.state);
    }
    
    componentWillUnmount() {
    }
    
    render() {
        let users ;
        if(this.state.users)
        {
            users = this.state.users.map(user=><>
                <p>{user.jobpost }</p>
                <p>{user.companyname}</p>
                </>);
        }     
           return (
            <div>
            <h1>{this.state.msg}</h1>
            <h2>It is {users}.</h2>
            </div>
            );
        }
    }
    
    export default CategoryJob;