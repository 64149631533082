
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WFooter from "components/Footers/WFooter.js";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import CommonP from "components/Headers/Common.js";
function Sitemap() {


  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
    <CommonP /> 
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/WajidLogo.png")}
              />
            </div>
            <div className="name"> <h4 className="title">Site Map <br /></h4></div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="10">
              <h6>
                <table>
                    <tr><a href="/index"> Home</a></tr>
<tr>
    <td>Search By</td>
   
</tr>
<tr><td></td>
<td><a href="/CityDetails">++ City</a> &nbsp;&nbsp;&nbsp;</td>
</tr>
<tr><td></td>
    <td><a href="/Category">++ Category</a></td>
</tr>
<tr><a href="/about-page"> About Us</a></tr>
<tr><a href="/contact-page"> Contact Us</a></tr>
<tr><a href="/terms"> Terms &amp; Conditions</a></tr>
<tr><a href="/Disclaimer"> Disclaimer</a></tr>
<tr><a href="/sitemap"> Site Map</a></tr>

                </table>
              </h6>
              

            </Col>
          </Row>
          <br />
          
          
        </Container>
      </div>
      <WFooter />
    </>
  );
}

export default Sitemap;
