
import React, { Component } from "react";
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import Axios from 'axios';
import Actions from './Actions';

// reactstrap components
import {   Button,   Label,   FormGroup,   Input,   NavItem,   NavLink,   Nav,   TabContent,   TabPane,
  Container,   Row,   Col, } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WFooter from "components/Footers/WFooter.js";

import { Facebook, Twitter } from 'react-sharingbuttons';
 import 'react-sharingbuttons/dist/main.css'; 

   const url = "http://employee.mjaisstore.com/Job/"; 
  const shareText = 'Share';
  

class JobDetail extends Actions {
  
  constructor(props) {
    super(props);
    this.state = {user:null,msg:"API NOT ...."};
}

componentDidMount() {
  
    let id = this.props.match.params.id;
    this.fetchUser(id);
    
}

componentWillUnmount() {
  
}

render() {
    let user ;
    
    if(this.state.user)
        {
            user = <>

<table>

  <tr>
    <td></td>
    
  </tr>
  <tr>
    
    <td><Link  to={{ pathname: "/City/"+ this.state.user.city }}> <h6> 
    <i className="fa fa-heart heart" /> 
       See Other  {this.state.user.city} Jobs &nbsp;&nbsp;&nbsp; </h6></Link></td>
    
    <td>
    <Link  to={{ pathname: "/CategoryData/"+ this.state.user.category }}> <h6>  
      <i className="fa fa-heart heart" /> 
        See Other  {this.state.user.category} Jobs &nbsp;&nbsp;&nbsp; </h6></Link>  </td>

        <td>
    <Link  to={{ pathname: "/Category/" }}> <h6>  
      <i className="fa fa-heart heart" /> 
        Check More Jobs by Categories </h6></Link>  </td>
        
  </tr>
</table>

        
        <br/>
            <table >
              <tr>
                <td>
               
                  <strong>Job Post</strong></td>
                <td className="title"><strong>
                 <h5> {this.state.user.jobpost}</h5>
                  </strong>
                
                  </td>
                  <td></td>
                <td> 
                
                 <a href={this.state.user.link} target="blank"> <u> <strong> {this.state.user.sources}</strong></u></a>  
     {/* <Button className="btn-round" color="info" href={this.state.user.link} target="blank"> {this.state.user.sources}  </Button> */}
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Facebook url={url +this.state.user.id} text={shareText}  /></td>
                </tr> 
                <tr>
                  <td><strong>Category </strong></td>
                  <td className="title"> <h5> {this.state.user.category} </h5></td>
                  <td><strong>Salary </strong></td>
                  <td className="title"> <h6> {this.state.user.salary}</h6></td>
                </tr>

                <tr>
                  <td><strong>Location </strong></td>
                  <td className="title"><h5> {this.state.user.JobLocation }</h5></td>
                  <td><strong>City </strong></td>
                  <td className="title"> <h5> {this.state.user.city}</h5></td>
                </tr>

                <tr>
                  <td><strong>Total Positions </strong></td>
                  <td className="title"> <h5> {this.state.user.JobPosition }</h5></td>
                  <td><strong>Job Type </strong></td>
                  <td className="title"> <h5> {this.state.user.JobType }</h5></td>
                </tr>

               
                <tr>
                  <td><strong>Last Date to Apply </strong></td>
                  <td className="title"> <h5> {this.state.user.ApplyBefore  }</h5></td>
                  <td><strong>Posting Date </strong></td>
                  <td className="title"> <h5> {this.state.user.PostingDate }</h5></td>
                </tr>


                <tr>  <td><hr/></td> <td><hr/></td><td><hr/></td><td><hr/></td> </tr>
                <tr>
                  <td><strong>Company Name</strong></td>
                  <td className="title"> <h6> {this.state.user.companyname}</h6></td>
                </tr>
                <tr>
                  <td><strong>Company Contact</strong></td>
                  <td className="title"> <h5> {this.state.user.contact}</h5></td>
                
                  <td><strong>Company Email</strong></td>
                  <td  className="title" style={{textTransform: "lowercase" }}> <h5> {this.state.user.companyEmail}</h5></td>
                </tr>
                <tr>  <td><hr/></td> <td><hr/></td><td><hr/></td><td><hr/></td> </tr>



</table>
<table>
<tr>
                  <td><strong>Education </strong></td>
                  <td className="title" > <h5>{this.state.user.Education }</h5></td>
                </tr>
                <tr><td></td><td><hr/></td></tr>
                <tr>
                  <td><strong>Career Level </strong></td>
                  <td className="title" > <h5>{this.state.user.CareerLevel  }</h5></td>
                  </tr>
                  <tr><td></td><td><hr/></td></tr>
                <tr>
                  <td><strong>Skills Needed </strong></td>
                  <td className="title" > <h5>{this.state.user.JobSkills }</h5></td>
                </tr>
                <tr><td></td><td><hr/></td></tr>

<tr>
                  <td><strong>Job Rewards &amp; Benefits </strong></td>
                  <td className="title" ><h5> {this.state.user.JobBenefits}</h5></td>
                </tr>
                <tr><td></td><td><hr/></td></tr>
                </table>
                <table>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td><strong>Remaining Details </strong></td>
                  <td className="title" > <p> {this.state.user.details}</p></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                </tr>

            </table>
            </>
        }     
           return (
            
            <>
            <ExamplesNavbar />
            <ProfilePageHeader />
            
      
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/WajidLogo.png")}
              />
            </div>
            <div className="name"> <h3 className="title">Job Details  </h3>
            
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="9">
            
 
 <p  className="name" style={{textTransform: "capitalize" }}  > {user}.</p>            
 <br/>
            </Col>
          </Row>
          <br />
          
           
          
        </Container>
      </div>
      <WFooter />
      </>
            );
        
             
    
}       
}

export default JobDetail;
