import React from "react";

import {Provider} from './crud/Context';
import AllUsers from './crud/GetUsers';
import Actions from './crud/Actions';


// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import Table from "reactstrap/lib/Table";
import JobAlert from "views/index-sections/SectionAlert.js";
// core components
import CommonP from "components/Headers/Common.js";
class WajidJob  extends Actions  {
  render(){
    const contextValue = {
         all_users:this.state.users,
        get_users:this.fetchUsers,
        editMode:this.editMode,
        cancelEdit:this.cancelEdit,
        handleUpdate:this.handleUpdate,
        handleDelete:this.handleDelete,
        insertUser:this.insertUser 
    }
  
  return (
    <>
    <CommonP /> 
      <div className="section section-dark">
        <Container>
          <Row className="example-page">
          <JobAlert />
          
          <Provider value={contextValue}>
          <AllUsers/>
          </Provider>
                  </Row>
        </Container>
      </div>{" "}
    </>
  );
}
}
export default WajidJob;
