//React.useState(); 
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import  { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components

import {
  Button,
  Collapse, 
  NavbarBrand, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledCollapse, UncontrolledDropdown,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function SearchBox()
{
     let [state, setState] = useState("")
     let history = useHistory();
     return <>
      <form onSubmit={(evt)=>{
          evt.preventDefault();
          history.push("/Search/"+state);
      }}>
      <table>
        <tr>
          <td><input type="text"  className="form-control" placeholder="Search" value={state} onChange={(evt)=>{
          setState(evt.target.value);
        }}/></td>
          <td><button type="submit"  className="btn btn-primary "><i className="nc-icon nc-compass-05" /></button></td>
          </tr>
        
      </table>
        
        
      </form>
     </>
}


function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (  document.documentElement.scrollTop > 299 ||  document.body.scrollTop > 299
      ) {  setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 || document.body.scrollTop < 300
      ) {  setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (

<Navbar   className={classnames("fixed-top", navbarColor)}  color-on-scroll="300" expand="lg">
<Container>

<NavbarBrand data-placement="bottom" to="/landing-page" title="Wajid" tag={Link}  >
            WAJID &nbsp;&nbsp;&nbsp; -- Job Finder </NavbarBrand>
                <button
                  aria-controls="navbarSupportedContent"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-target="#navbar-menu"
                  data-toggle="collapse"
                  id="navbar-menu"
                  type="button"
                >
                  <span className="navbar-toggler-bar" />
                  <span className="navbar-toggler-bar" />
                  <span className="navbar-toggler-bar" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar-menu">
                  <Nav className="mr-auto" navbar>
               
  <UncontrolledDropdown nav inNavbar>  
          <DropdownToggle  aria-expanded={false} aria-haspopup={true}
           caret color="default" data-toggle="dropdown"  href="#pablo"  id="dropdownMenuButton"
                        nav onClick={(e) => e.preventDefault()}  role="button"  >  Search By 
                      </DropdownToggle>

                      <DropdownMenu aria-labelledby="dropdownMenuButton" className="dropdown-info" >
                        
           <DropdownItem href="/Category" > Category </DropdownItem>
<DropdownItem divider />
                        
           <DropdownItem  href="/CityDetails" > City </DropdownItem>
                        {/* <DropdownItem divider /> */}
                        
          {/* <DropdownItem  href="#pablo" onClick={(e) => e.preventDefault()} > Another separated link </DropdownItem>
                      
           */}          
           </DropdownMenu>
                    </UncontrolledDropdown>

                    <NavItem>
            <NavLink href="/about-page" ><i className="nc-icon nc-book-bookmark" /> About  </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="/contact-page" > <i className="nc-icon nc-book-bookmark" /> Contact 
              </NavLink>
            </NavItem>

            
            <NavItem>
              <NavLink  data-placement="bottom"  href="https://www.facebook.com/WajidJobFinder/"
                target="_blank" title="Like us on Facebook" >
                <i className="fa fa-facebook-square" />  <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink  data-placement="bottom"  href="https://www.linkedin.com/company/wajid/"
                target="_blank" title="Follow us on LinkedIn" >
                <i className="fa fa-linkedin-square" />  <p className="d-lg-none">LinkedIn</p>
              </NavLink>
            </NavItem>
           

                  </Nav>

                </UncontrolledCollapse>

</Container>
 <Container>
<table >
  <tr>
    
    <td>
      {/* <form>
    <input type="text" name="search"  className="form-control" placeholder="Search"/> 
      <button  type="submit" className="btn btn-primary"><i className="nc-icon nc-compass-05" /></button>
      </form> */}
       <SearchBox/>
      </td>
    
    
  </tr>
</table>
</Container> 
              </Navbar>



   /*  <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/landing-page"
            title="Coded by Creative Tim"
            tag={Link}
          >
            WAJID &nbsp;&nbsp;&nbsp; -- Job Finder
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <UncontrolledCollapse navbar toggler="#navbar-menu">
          <Nav className="mr-auto" navbar>
            {/* <NavItem>
              <NavLink to="/index" tag={Link}><i className="nc-icon nc-layout-11" /> Components</NavLink>
            </NavItem> 

             <NavItem>
            <NavLink href="/about-page" ><i className="nc-icon nc-book-bookmark" /> About Us </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="/contact-page" > <i className="nc-icon nc-book-bookmark" /> Contact Us 
              </NavLink>
            </NavItem> */
            /* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fa fa-twitter" />
                <p className="d-lg-none">Twitter</p>
              </NavLink>
            </NavItem> */
            /*  */
            /* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/WajidJobFinder/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem> */
          /* </Nav>
          </UncontrolledCollapse>
        </Collapse>
      </Container>
    </Navbar> */ 
          
  );
          
}

export default ExamplesNavbar;
