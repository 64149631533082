import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Label, FormGroup, Input, NavItem,  NavLink, Nav,  TabContent, TabPane,
  Container, Row, Col, Modal } from "reactstrap";
import {  UncontrolledCollapse, NavbarBrand,  Navbar, 
  } from "reactstrap";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WFooter from "components/Footers/WFooter.js";
import JobAlert from "views/index-sections/SectionAlert.js";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import CommonP from "components/Headers/Common.js";
function CityDetails() {
  var category = "CivilAndConstruction";

  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  }
  
  
   const [activeTab, setActiveTab] = React.useState("1");
  // const cat;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
 
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
    <CommonP /> 
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">  <img alt="..."  className="img-circle img-no-padding img-responsive"
                src={require("assets/img/WajidLogo.png")} />  </div>
             <div className="name"> <h4 className="title"> &nbsp; Search BY City <br /></h4></div> 

             <JobAlert />


          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              
<div className="title">
                
              </div>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>

                  <NavItem>
                      <NavLink className={activeTab === "1" ? "active" : ""}
                        onClick={() => {  toggle("Pak"); }}>
                        Asia   </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink className={activeTab === "1" ? "active" : ""}
                        onClick={() => {  toggle("Africa"); }}>
                        Africa   </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink className={activeTab === "1" ? "active" : ""}
                        onClick={() => {  toggle("North"); }}>
                        North America   </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Others  
                      </NavLink>
                    </NavItem>
                   
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === "3" ? "active" : ""}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Messages
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab} className="text-left">

              <TabPane tabId="1"  >
              <table >
                    <tr >
                      <td>
                      <Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Remote" }}> <strong> Remote</strong> </Link>   
                      </td>
                    </tr>
                    </table>
                  </TabPane>  
              <TabPane tabId="Pak"  >
                        <table>
                        <tr><td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Pakistan" }}> <strong>PAKISTAN</strong> </Link></td> </tr>
                        <tr><td>&nbsp;</td></tr>
                    <tr>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Punjab" }}> <strong> Punjab</strong> </Link>   </td>
                    </tr>
                    
                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Bahawalnagar" }}> <strong> Bahawalnagar</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Bahawalpur" }}> <strong> Bahawalpur</strong> </Link>  </td>

<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Bannu" }}> <strong> Bannu</strong> </Link>  </td>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Chakwal" }}> <strong> Chakwal</strong> </Link>  </td>

                    </tr>

                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Gilgit" }}> <strong> Gilgit</strong> </Link>  </td>
                      
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Gujranwala" }}> <strong> Gujranwala</strong> </Link>  </td>
                         <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Gwadar" }}> <strong> Gwadar</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Hunza" }}> <strong> Hunza </strong> </Link>  </td>
                       </tr>
                       <tr> 
                       <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Hyderabad" }}> <strong> Hyderabad</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Islamabad" }}> <strong> Islamabad</strong> </Link>  </td>

                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Juglot" }}> <strong> Juglot</strong> </Link>  </td>

                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "KalaShahKaku" }}> <strong> Kala Shah Kaku</strong> </Link>  </td>
                       
                    </tr>

                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Karachi" }}> <strong> Karachi</strong> </Link>  </td>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Larkana" }}> <strong> Larkana</strong> </Link>  </td>                      
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Lahore" }}> <strong> Lahore</strong> </Link>  </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Multan" }}> <strong> Multan</strong> </Link>  </td>
                      
                    </tr>

<tr>
  
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Muzaffargarh" }}> <strong> Muzaffargarh</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Narowal" }}> <strong> Narowal</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Nawabshah" }}> <strong> Nawabshah</strong> </Link>  </td>                        
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Okara" }}> <strong> Okara</strong> </Link>  </td>
               
</tr>
<tr>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Pasrur" }}> <strong> Pasrur</strong> </Link>  </td>         
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Peshawar" }}> <strong> Peshawar</strong> </Link>  </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Quetta" }}> <strong> Quetta</strong> </Link>  </td>
 <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "RahimYarkhan" }}> <strong> Rahim Yar khan</strong> </Link>  </td>  

                                        
</tr>

                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Rawalpindi" }}> <strong> Rawalpindi</strong> </Link>  </td>                        
                    
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Sargodha" }}> <strong> Sargodha</strong> </Link>  </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "ShahKot" }}> <strong> ShahKot</strong> </Link>  </td>                        
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Sharqpur" }}> <strong> Sharqpur</strong> </Link>  </td>
                    
                    </tr>
                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Skardu" }}> <strong> Skardu</strong> </Link>  </td>                        
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Sharqpur" }}> <strong> Sharqpur</strong> </Link>  </td>                                            
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "TobaTekSingh" }}> <strong> Toba Tek Singh</strong> </Link>  </td>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Vehari" }}> <strong> Vehari</strong> </Link>  </td>
                
                    </tr>
                   <tr>
                   <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Wah" }}> <strong> Wah</strong> </Link>  </td>        
                   </tr>
                    <tr>  <td><hr/></td><td><hr/></td><td><hr/></td><td><hr/></td> </tr>

                    <tr><td><strong>JAPAN</strong></td> </tr>
                    <tr>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Shizuoka" }}> <strong> Shizuoka</strong> </Link>   </td>
                    </tr>

                    <tr>  <td><hr/></td><td><hr/></td><td><hr/></td><td><hr/></td> </tr>

                    <tr><td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "UnitedArabEmirates" }}> <strong> UNITED ARAB EMIRATES</strong> </Link></td> </tr>
                    <tr>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "AbuDhabi" }}> <strong> Abu Dhabi</strong> </Link>   </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Ajman" }}> <strong> Ajman </strong> </Link>   </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "AlAin" }}> <strong> Al Ain </strong> </Link>   </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Dubai" }}> <strong> Dubai </strong> </Link>   </td>
                    </tr>
<tr>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Sharjah" }}> <strong> Sharjah </strong> </Link>   </td>
<td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "UmmAlQuwain" }}> <strong> Umm Al Quwain </strong> </Link>   </td>                        
</tr>

                        </table>
              </TabPane>
              <TabPane tabId="Africa"  >
              <tr><td><strong>SOUTH AFRICA</strong></td> </tr>
                    <tr>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Johannesburg" }}> <strong> Johannesburg</strong> </Link>   </td>
                    </tr>
              </TabPane>

              <TabPane tabId="North"  >
              <tr>
              <td></td>
              <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "UnitedStatesofAmerica" }}> <strong> UNITED STATES of AMERICA</strong> </Link>   </td>
              </tr>
                    <tr>
                    <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Chicago" }}> <strong>  Chicago</strong> </Link>   </td>
                      <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "LosAngeles" }}> <strong> Los Angeles</strong> </Link>   </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "NewYork" }}> <strong>  New York</strong> </Link>   </td>
                        <td><Link className="btn-round mr-1" color="primary" to={{
                        pathname: "/City/"+ "Washington" }}> <strong>  Washington</strong> </Link>   </td>
                    </tr>
                  </TabPane>
                  
              </TabContent>



            </Col>
          </Row>
          <br />
          
          
        </Container>


      </div>

      
      <WFooter />
    </>
  );
}

export default CityDetails;
