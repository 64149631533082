import {  Button, Label, FormGroup, Input, NavItem, NavLink, Nav, TabContent, TabPane, Container,
    Row, Col, } from "reactstrap";
  import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
  import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
  import WFooter from "components/Footers/WFooter.js";


import React, { Component } from "react";
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import Axios from 'axios';
import Actions from 'views/index-sections/crud/Actions.js';
import JobAlert from "views/index-sections/SectionAlert.js";
import CommonP from "components/Headers/Common.js";
var cat;
class CategoryDetails extends Actions {
    constructor(props) {
        super(props);
        this.state = {users:null,msg:"API NOT ...."};
    }
    
    componentDidMount() {
        let category = this.props.match.params.category;
        this.fetchcat(category);
        cat= category;
        /* console.log("%c___", "background:blue;padding:20px;");
        
        console.log(this.state); */
    }
    
    componentWillUnmount() {
    }
    
    render() {


        let users ;
        if(this.state.users) {
            users = this.state.users.map(user=><>
            

    <tr className="text-left">
        <td><strong>{user.jobpost }</strong> </td>
        <td>{user.companyname} </td>
        <td>{user.city } </td>
        <td>&nbsp;&nbsp;&nbsp;{user.PostingDate } </td>
        <td className="text-right"><Link to={{ pathname: "/Job/"+user.id   }}><strong>Details</strong>   </Link> </td>
    </tr>
    <tr>
      <td><hr/></td>
      <td><hr/></td>
      <td><hr/></td>
      <td><hr/></td>
      <td><hr/></td>
      </tr>

                </> );
        }     
        
       
  
           return (
            <>
            <CommonP /> 
            <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/WajidLogo.png")}
              />
            </div>
            <div className="name"> <h4 className="title">JOB POSTS of [ <strong> {cat} </strong> ]<br /></h4>
            <JobAlert />
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <p> {/* WAJID */}  </p>
              <br />

              <div>
            <table>
    <tr>
        <td><strong><h4><strong>Job Post</strong> </h4></strong></td>
        <td><strong><h4><strong>Company Name</strong></h4></strong></td>
        <td><strong><h4><strong>City</strong></h4></strong></td>
        <td><strong><h4><strong>Posting Date</strong></h4></strong></td>
        <td><strong><h4><strong>Details</strong></h4></strong></td>
    </tr>
    <tr><td>&nbsp;</td></tr>
             {users}
             </table>
            </div>

            <br/><br/><br/>
              <div className="name">
              <h6 className="description"><a href="http://www.mjaisstore.com/Info/" target="blank"><strong>A Project by MJais Software Consultancy</strong></a></h6>
            </div>

              {/* <Button className="btn-round" color="default" outline>
                <i className="fa fa-cog" /> Settings
              </Button> */}
            </Col>
          </Row>
          <br />
          
          
        </Container>
      </div>
      <WFooter />


           
            </>

            );
            
        }
    }
    
    export default CategoryDetails;