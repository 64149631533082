
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Label, FormGroup, Input, NavItem,  NavLink, Nav,  TabContent, TabPane,
  Container, Row, Col, } from "reactstrap";

import {  UncontrolledCollapse, NavbarBrand,  Navbar, 
  } from "reactstrap";
  
  
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WFooter from "components/Footers/WFooter.js";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import JobAlert from "views/index-sections/SectionAlert.js";
import CommonP from "components/Headers/Common.js";
function Category() {
  var category = "CivilAndConstruction";
  
   const [activeTab, setActiveTab] = React.useState("1");
  // const cat;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
 
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
    <CommonP /> 
      <ExamplesNavbar />
      
      <ProfilePageHeader />
      
      <div className="section profile-content">
      
      <Container>
          <div className="owner">
          
            <div className="avatar">
            
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/WajidLogo.png")}
              />
            </div>
             <div className="name"> 
             
             <h4 className="title"> &nbsp; Search BY <br /></h4>
             
             </div> 
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
            
<div className="title">
<JobAlert />  
              </div>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Category 
                      </NavLink>
                    </NavItem>
                    
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === "3" ? "active" : ""}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Messages
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab} className="text-left">
                <TabPane tabId="1">
                 {/*  <Button className="btn-round mr-1" color="primary" type="button"  >
                  Civil &amp; Construction </Button> */}
                 
                 <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "ArtsAndEntertainment" }}> <strong> Arts And Entertainment</strong> </Link><br/>

                 <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Biomedical" }}> <strong> Biomedical</strong> </Link><br/>
                        
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Chemical" }}> <strong> Chemical</strong> </Link>  <br/>    

                  <Link className="btn-round mr-1" color="primary"   to={{
                     pathname: "/CategoryData/"+ category }}><strong> Civil And Construction</strong> </Link> <br/>
                       
                       <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "DisasterRelief" }}> <strong> Disaster Relief</strong> </Link>  <br/>    

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Education" }}><strong>  Education</strong> </Link>  <br/>    

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Electronics" }}>  <strong>Electronics</strong> </Link>  <br/>    

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "FinancialServices" }}> <strong> Financial Services</strong> </Link>  <br/>    

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "FlightOperations" }}> <strong> Flight Operations</strong> </Link>  <br/> 

                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "FoodAndBeverage" }}> <strong> Food And Beverage</strong> </Link>  <br/> 
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Government" }}> <strong> Government</strong> </Link>  <br/> 
                        
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "HealthCare" }}> <strong> HealthCare</strong> </Link>  <br/>

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "HumanResource" }}> <strong> Human Resource</strong> </Link>  <br/>

                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "HospitalityManagement" }}> <strong> Hospitality Management</strong> </Link>  <br/> 
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "InformationTechnology" }}> <strong> Information Technology</strong> </Link>  <br/> 
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "LawAndJustice" }}> <strong> Law And Justice</strong> </Link>  <br/> 
<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "LogisticsAndTransport" }}> <strong> Logistics And Transport </strong> </Link>  <br/>                         
                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "Management" }}> <strong> Management</strong> </Link>  <br/> 

                        <Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "MediaAndCommunications" }}> <strong> Media And Communications</strong> </Link>  <br/> 

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "ProductionAndManufacturing" }}> <strong> Production And Manufacturing</strong> </Link>  <br/> 

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "SafetyAndSecurity" }}> <strong> Safety And Security</strong> </Link>  <br/> 

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "SalesAndMarketing" }}> <strong> Sales And Marketing</strong> </Link>  <br/> 

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "SocialServices" }}> <strong> Social Services</strong> </Link>  <br/> 

<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "TextileAndClothing" }}> <strong> Textile And Clothing</strong> </Link>  <br/> 
<Link className="btn-round mr-1" color="primary"   to={{
                        pathname: "/CategoryData/"+ "WritingAndProofreading" }}> <strong> Writing And Proofreading</strong> </Link>  <br/> 
                </TabPane>
               
                {/* <TabPane tabId="3">
                  <p>Here are your messages.</p>
                </TabPane> */}
              </TabContent>



            </Col>
          </Row>
          <br />
          
          
        </Container>


      </div>

      
      <WFooter />
    </>
  );
}

export default Category;
