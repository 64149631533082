import React, { Component } from "react";
import {useParams} from 'react-router-dom';
    
    
import Axios from 'axios';


import Actions from './Actions';

class DetailN extends Actions {
    constructor(props) {
        super(props);
        this.state = {user:null,msg:"API NOT ...."};
    }
    
    componentDidMount() {
        let id = this.props.match.params.id;
        this.fetchUser(id);
        var postN;

        console.log("%c___", "background:red;padding:20px;");
        
        console.log(this.state);
    }
    
    componentWillUnmount() {
    }
    
    render() {
        let user ;
        if(this.state.user)
        {
            user = <>
            <p>{this.state.user.jobpost}</p>
            <p>{this.state.user.details}</p>
              
            </>
        }     
           return (
            <div>
            <h1>{this.state.msg}</h1>
            <h2>It is {user}.</h2>
            <br/><br/>
            
            </div>
            );
        }
    }
    
    export default DetailN;