import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import React from "react";
import ReactGA from 'react-ga';
import { useEffect } from 'react';

// reactstrap components

// core components

function ProfilePageHeader() {

 

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });


  

  return (
    <>
      <div  style={{ backgroundImage: "url(" + require("assets/img/about.jpg") + ")",  }}
        className="page-header page-header-xs"  data-parallax={true}  ref={pageHeader} >
         <div className="filter" /> 
      </div>
    </>
  );
}

export default ProfilePageHeader;
