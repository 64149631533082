import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Login() {
  return (
    <>
      <div  className="section section-image section-login" 
        style={{  backgroundImage: "url(" + require("assets/img/login-image.jpg") + ")",  }}  >
        <Container>
          <Row>
            <Col className="mx-auto" lg="4" md="6">
            <div className="col text-center">
                <Button className="btn-round" outline color="neutral"  href="/Index" size="lg" > Home  </Button>
              </div>
              <Card className="card-register">
              
                <h3 className="title mx-auto"><strong> Login Your Credentials</strong> </h3>
                
                <Form className="register-form">
                  <label>Email</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText> <i className="nc-icon nc-email-85" /> </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" />
                  </InputGroup>

                  <label>Password</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText> <i className="nc-icon nc-key-25" />  </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" />
                  </InputGroup>

                  <Button block className="btn-round" color="danger"  type="button" >  Login </Button>
                </Form>
              {/*   <div className="forgot">
                  <Button  className="btn-link" color="danger"  href="#pablo"  onClick={(e) => e.preventDefault()}  >
                    Forgot password? </Button>
                </div> */}
              </Card>
              
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default Login;
