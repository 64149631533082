
import { Link } from "react-router-dom";
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import ReactGA from 'react-ga';
import { useEffect } from 'react';

// core components

function LandingPageHeader() {


  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/jobsFront.jpg") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>WAJID</h1>
            <h3>Job Finder
            </h3>
            <br />

            <Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/CategoryData/"+ "Government" }}> <strong style={{color:"white"}} > Government Jobs</strong> </Link> </Button>

<Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/CategoryData/"+ "InformationTechnology" }}> <strong style={{color:"white"}} > Information Technology Jobs</strong> </Link> </Button>

<Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/CategoryData/"+ "FinancialServices" }}> <strong style={{color:"white"}} > Finance Jobs</strong> </Link> </Button>

<Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/Category" }}> <strong style={{color:"white"}} > Search By Category</strong> </Link> </Button>
<br/><br/>
<Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/City/"+ "Dubai" }}> <strong style={{color:"white"}} > Dubai Jobs</strong> </Link> </Button>

<Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/City/"+ "LosAngeles" }}> <strong style={{color:"white"}} > LosAngeles Jobs</strong> </Link> </Button>
             <Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/City/"+ "Islamabad" }}> <strong style={{color:"white"}} > Islamabad Jobs</strong> </Link> </Button>
             
             <Button  className="btn-round mr-1" color="neutral" outline> <Link className="btn-round mr-1"    to={{
             pathname: "/CityDetails" }}> <strong style={{color:"white"}} > Search By City</strong> </Link> </Button>

            <Button href="#StartPoint" className="btn-round mr-1" color="neutral"   outline>
            <i className="fa fa-heart heart" />Know More </Button>
            {/* 
            <Button className="btn-round" color="neutral" type="button" outline> Download </Button> */}
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
