import React from "react";
import { Redirect } from 'react-router';

// reactstrap components
import {   Button, Label, FormGroup, Input,  NavItem,  NavLink,  Nav,  TabContent,  TabPane,  Container,
  Row,  Col, } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WFooter from "components/Footers/WFooter.js";
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import {Provider} from 'views/index-sections/crud/Context';
import AddUser from 'views/index-sections/crud/AlertInsert';
import Actions from 'views/index-sections/crud/Actions';

class JobEmail extends Actions {
    render(){
        const contextValue = {
            all_users:this.state.users,
            get_users:this.fetchUsers,
            editMode:this.editMode,
            cancelEdit:this.cancelEdit,
            handleUpdate:this.handleUpdate,
            handleDelete:this.handleDelete,
            insertUser:this.insertUser
    }
    if (this.state.isFilled) {
                    
        return <Redirect to = {{ pathname: "/Feedback" }} />;
      } 
  return (
    <Provider value={contextValue}>
    <div className="container-fluid bg-light">
          <div className="container p-5">
              <div className="card shadow-sm">
                  <h3 className="card-header text-center text-uppercase text-muted"> Free alerts for top private and govt jobs</h3>
                  <div className="card-body">
                      <div className="row">
                          <div className="col-md-12">
                              { <AddUser/> }
                          </div>
                       </div>
                  </div>
              </div>
  
          </div>

          <div  style={{marginTop: "60px",}}> <WFooter /></div>
          </div>
  </Provider>
  

  );
}
}

export default JobEmail;
