import React from "react";

// reactstrap components
import { Button, Row, Container } from "reactstrap";

function WFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <hr/>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>Developed by <a href="http://www.mjaisstore.com/Info" target="_blank"  > MJais Software Consultancy </a>
              </li>
            <li className="text-left">
            <a href="/about-page" >About Us</a>
            <a href="/contact-page" >Contact Us</a>
              <a href="/terms" >Terms &amp; Conditions</a> 
            <a href="/Disclaimer" >Disclaimer</a>
            </li>
            <li className="text-left"> 
            <a href="/sitemap" >SiteMap</a>
            Search by 
            <a href="/CityDetails" >City</a>
            <a href="/Category" >Category</a>
            </li>
             
            </ul>
          </nav>
          


          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> 

   <Button className="btn-just-icon btn-neutral" href="https://www.facebook.com/WajidJobFinder/" target= "blank" >
                      <i className="fa fa-facebook" /> </Button>

  <Button className="btn-just-icon btn-neutral" href="https://www.linkedin.com/company/wajid/" target= "blank" >
                      <i className="fa fa-linkedin" /> </Button>
                      {/* <Button className="btn-just-icon btn-neutral" href="#pablo" onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" /> </Button> */}

            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default WFooter;
