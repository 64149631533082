import React,{Component} from 'react';
import {AppContext} from 'views/index-sections/crud/Context.js';

class AlertInsert extends Component {
    static contextType = AppContext;

    insertUser = (event) => {
        this.context.insertUser(event,this.username.value,this.useremail.value);
    }

    render(){
        return (
            
            <form onSubmit={this.insertUser}>
                
            <div className="form-row">
                <div className="form-group col-sm-5">
                    <label className="font-weight-bold">City</label>
                    <select name="username" ref={(val) => this.username = val} className="form-control">            
                    
                    <option value="Remote">Remote</option>

<option value="Pakistan">PAKISTAN</option>
<option value="Punjab">Punjab</option>

<option value="Bahawalnagar">Bahawalnagar</option>
<option value="Bahawalpur">Bahawalpur</option>

<option value="Bannu">Bannu</option>
<option value="Chakwal">Chakwal</option>
<option value="Gilgit">Gilgit</option>
<option value="Gujranwala">Gujranwala</option>

<option value="Gwadar">Gwadar</option>
<option value="Hunza">Hunza</option>
<option value="Hyderabad">Hyderabad</option>
<option value="Islamabad">Islamabad</option>

<option value="Juglot">Juglot</option>
<option value="KalaShahKaku">Kala Shah Kaku</option>
<option value="Karachi">Karachi</option>
<option value="Larkana">Larkana</option>


<option value="Lahore">Lahore</option>
<option value="Multan">Multan</option>
<option value="Muzaffargarh">Muzaffargarh</option>
<option value="Narowal">Narowal</option>

<option value="Nawabshah">Nawabshah</option>
<option value="Okara">Okara</option>
<option value="Pasrur">Pasrur</option>
<option value="Peshawar">Peshawar</option>

<option value="Quetta">Quetta</option>
<option value="RahimYarkhan">Rahim Yar Khan</option>
<option value="Sargodha">Sargodha</option>
<option value="ShahKot">ShahKot</option>

<option value="Sharqpur">Sharqpur</option>
<option value="Skardu">Skardu</option>
<option value="TobaTekSingh">Toba Tek Singh</option>
<option value="Vehari">Vehari</option>

<option value="Wah">Wah</option>

<option value="JAPAN">JAPAN</option>
<option value="Shizuoka">Shizuoka</option>

<option value="UnitedArabEmirates">United Arab Emirates</option>

<option value="AbuDhabi">Abu Dhabi</option>
<option value="Ajman">Ajman</option>
<option value="AlAin">Al Ain</option>
<option value="Dubai">Dubai</option>


<option value="Sharjah">Sharjah</option>
<option value="UmmAlQuwain">Umm Al Quwain</option>

<option value="Africa">Africa</option>
<option value="Johannesburg">Johannesburg</option>

<option value="UnitedStatesofAmerica">United States of America</option>

<option value="Chicago">Chicago</option>
<option value="LosAngeles">Los Angeles</option>
<option value="NewYork">New York</option>


<option value="Washington">Washington</option>


          </select>
                    {/* <input type="text" name="username" ref={(val) => this.username = val} className="form-control" placeholder="Name"/> */}
                </div>
                <div className="form-group col-sm-5">
                    <label className="font-weight-bold">Email</label>
                    <input type="email" name="useremail" ref={(val) => this.useremail = val} className="form-control" placeholder="Email"/>
                </div>
                <div className="form-group col-sm-2 text-right">
                    <button type="submit" className="btn btn-primary">Send Alerts</button>
                </div>
            </div>
        </form>  
        );
    }
}
export default AlertInsert;