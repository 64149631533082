import { Redirect } from 'react-router';
import React from 'react';
import Axios from 'axios';
class Actions extends React.Component{
    state = {
        users:[],
        isFilled: false 
    }

    // FETCH USERS FROM DATABASE
    fetchUsers = () => {
        Axios.get('http://mjaisstore.com/Wajid/all-users.php')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    users:data.users.reverse()
                });
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

//get category
fetchcat = (category) => {
    Axios.get('http://mjaisstore.com/Wajid/category.php',{
        params: {
            category: category
        }
      })
    .then(({data}) => {
        if(data.success === 1){
            console.log("FROM ACTIONS", data.users);
            this.setState({
                users:data.users.reverse(),
                msg:"API CAT UPDATED"
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
} 


//get city
fetchcity = (city) => {
    Axios.get('http://mjaisstore.com/Wajid/city.php',{
        params: {
            city: city
        }
      })
    .then(({data}) => {
        if(data.success === 1){
            console.log("FROM ACTIONS", data.users);
            this.setState({
                users:data.users.reverse(),
                msg:"API city UPDATED"
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
} 
    //get single user
    fetchUser = (id) => {
        Axios.get('http://mjaisstore.com/Wajid/userdata.php',{
            params: {
              id: id
            }
          })
        .then(({data}) => {
            if(data.success === 1){
                /* console.log("%c___", "background:red;padding:20px;");
                console.log(data.user[0]); */
                this.setState({
                    user:data.user[0],
                    msg:"API UPDATED"
                });
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
     // ON EDIT MODE
     editMode = (id) => {
        let users = this.state.users.map(user => {
            if(user.id === id){
                user.isEditing = true;
                return user;
            }
            user.isEditing = false;
            return user;
        });

        this.setState({
            users
        });
    }

    //CANCEL EDIT MODE
    cancelEdit = (id) => {
        let users = this.state.users.map(user => {
            if(user.id === id){
                user.isEditing = false;
                return user;
            }
            return user
            
        });
        this.setState({
            users
        });
    }

     // INSERT USER
     insertUser = (event,user_name,user_email) => {
        event.preventDefault();
        event.persist();
        Axios.post('http://mjaisstore.com/Wajid/add-jobalert.php',{
            user_name:user_name,
            user_email:user_email
        })
        .then(function ({data}) {
            if(data.success === 1){
                this.setState({
                    users:[
                        {"id":data.id,"user_name":user_name,"user_email":user_email},
                        ...this.state.users
                    ],
                    isFilled: true 
                });
                {/* <Redirect to="/Feedback" /> */}

                
                event.target.reset();
                
            }
            else{
                alert(data.msg);
            }
        }.bind(this))
        .catch(function (error) {
            console.log(error);
        });
    }


    
//searchpost
searchpost = (search) => {
    Axios.get('http://mjaisstore.com/Wajid/search.php',{
        params: {
            search: search
        }
      })
    .then(({data}) => {
        //console.log(data);
        if(data.success === 1){
            console.log("FROM ACTIONS", data.users);
            this.setState({
                users:data.users.reverse(),
                msg:"API search UPDATED"
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
} 
 
}

export default Actions;