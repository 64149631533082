import MessengerCustomerChat from 'react-messenger-customer-chat';
import React from "react";

function Common() {
    return (
        <>
      
      <MessengerCustomerChat   pageId="253736821988070"  appId="236481738079211" />
    </>
    )
}
export default Common;
